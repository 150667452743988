import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setBrokenGame, setCurrentBonus } from '../../gql';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinMode } from '../../utils';
import Animation from '../animations/animation';
import { createZoomAnimation } from '../animations/helper';
import { ViewContainer } from '../components/viewContainer';
import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH, eventManager } from '../config';
import {
  FREE_SPINS_COUNTER_ANIMATION_DELAY,
  FREE_SPINS_COUNTER_ANIMATION_LOOP,
  FREE_SPINS_COUNTER_ANIMATION_SCALE,
} from '../gameView/config';
import AutoResizeText from '../text/autoResizeText';

import { spinsStyle, textStyle } from './textStyles';

export class FreeSpinsCounter extends ViewContainer {
  private backUnit: PIXI.Sprite;

  private titleText: AutoResizeText;

  private spinsText: AutoResizeText;

  private pulsAnimation: Animation | null = null;

  private window = { width: 0, height: 0 };

  constructor() {
    super();
    this.backUnit = this.initBackUnit();
    this.titleText = this.initTitleText();
    this.spinsText = this.initSpinsText(10, 0);

    this.init();

    eventManager.addListener(
      EventTypes.UPDATE_FREE_SPINS_COUNT,
      (spins: number, curr: number, immediately: boolean): void => this.handleUpdate(spins, curr, immediately),
    );

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));

    eventManager.addListener(EventTypes.CHANGE_MODE, (settings: { mode: GameMode }) => {
      this.changeVisible(settings.mode);
    });
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, (settings: { mode: GameMode }) => {
      this.changeVisible(settings.mode);
    });

    if (setBrokenGame()) {
      this.handleUpdate(setCurrentBonus().rounds, setCurrentBonus().currentRound, true);
    }
  }

  private init(): void {
    this.name = 'counter';
    this.addChild(this.backUnit);
    this.addChild(this.titleText);
    this.addChild(this.spinsText);
    this.visible = false;
  }

  private initBackUnit() {
    const backUnit = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureBtn));
    backUnit.anchor.set(0.5);
    return backUnit;
  }

  private initTitleText(): AutoResizeText {
    const text = new AutoResizeText(i18n.t('freeSpins'), textStyle);
    text.anchor.set(0.5, 0.5);
    text.position.set(0, 0 - text.height);
    return text;
  }

  private initSpinsText(spins: number, currentSpin: number): AutoResizeText {
    const spinsText = new AutoResizeText(this.getFormatSpins(spins, currentSpin), spinsStyle);
    spinsText.anchor.set(0.5, 0.5);
    return spinsText;
  }

  private handleUpdate(spins: number, currentSpin: number, immediately: boolean): void {
    this.spinsText.text = this.getFormatSpins(spins, currentSpin);

    if (immediately) return;
    if (!this.visible) return;

    this.pulsAnimation = createZoomAnimation(
      this.spinsText,
      FREE_SPINS_COUNTER_ANIMATION_SCALE,
      FREE_SPINS_COUNTER_ANIMATION_DELAY,
      FREE_SPINS_COUNTER_ANIMATION_LOOP,
    );
    this.pulsAnimation?.start();
  }

  private getFormatSpins(spins: number, currentSpin: number): string {
    return `${currentSpin}/${spins}`;
  }

  private changeVisible(mode: GameMode) {
    this.visible = isFreeSpinMode(mode);
  }

  protected override resize(width: number, height: number): void {
    this.window = { width, height };
    this.handlePosition();
  }

  private handlePosition(): void {
    if (this.window.width >= this.window.height) {
      this.scale.set(1, 1);
      this.x = -30;
      this.y = GAME_CONTAINER_HEIGHT / 2;
    } else {
      this.scale.set(1, 1);
      this.x = GAME_CONTAINER_WIDTH - this.backUnit.width / 2;
      this.y = 820;
    }
  }
}
