import { Variables } from '../../config';

const FREE_SPINS_COUNTER_TEXT_CONFIG = {
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#ff4f4f', '#d64fff', '#4fd0ff', '#78ff4f'],
  fillGradientType: 0,
  stroke: '#00000066',
  dropShadowColor: 0x333333,
  dropShadowDistance: 3,
  miterLimit: 1,
};

export const textStyle = {
  ...FREE_SPINS_COUNTER_TEXT_CONFIG,
  fontSize: 35,
  strokeThickness: 5,
};

export const spinsStyle = {
  ...FREE_SPINS_COUNTER_TEXT_CONFIG,
  fontSize: 45,
  strokeThickness: 6,
};
