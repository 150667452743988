import { Container, Text } from 'pixi.js';

import { SpineInterface } from '../../../config/spine.generated';
import { Game } from '../../../game';
import Animation from '../../animations/animation';
import { StrictSpine } from '../../components/spine';
import { REELS_AMOUNT } from '../../config';
import { ConvertIndex } from '../slot';

import { textStyle } from './textStyles';

const animationSlotIndexes: ConvertIndex = {
  [0]: 5,
  [1]: 4,
  [2]: 3,
  [3]: 2,
  [4]: 1,
};

const slotIndexPer: { y: number; fontSize: number }[] = [
  { y: -160 - 280, fontSize: 42 }, // outer
  { y: -160 - 200, fontSize: 38 },
  { y: -160 - 130, fontSize: 36 },
  { y: -160 - 70, fontSize: 34 },
  { y: -160, fontSize: 32 }, // inner
];

type MultiplierSymbolAnimationNames = SpineInterface['symbol_multiplier']['animations'];

export class MultiplierSymbol extends Container {
  private spine: StrictSpine<'symbol_multiplier'>;

  private multiplierText: Text;

  public multiplier = 0;

  constructor(multiplierValue: number, reelId: number, slotIndex: number) {
    super();
    this.multiplier = multiplierValue;
    this.multiplierText = new Text(`x${multiplierValue}`, {
      ...textStyle,
      fontSize: slotIndexPer[slotIndex]!.fontSize,
    });
    this.multiplierText.angle = 360 - (360 / REELS_AMOUNT) * reelId;
    this.multiplierText.anchor.set(0.5);
    this.multiplierText.position.set(0, slotIndexPer[slotIndex]!.y);

    const spine = Game.getInstance().maker.spine('symbol_multiplier');
    this.spine = spine;

    this.addChild(spine);
    this.addChild(this.multiplierText);
  }

  public getSpineAnimation(slotIndex: number, animationAbbv: 'stop' | 'in' | 'lost' | 'win'): Animation {
    return this.spine.getAnimation(
      0,
      `step${this.getAnimationSlotIndex(slotIndex)}_${animationAbbv}` as MultiplierSymbolAnimationNames,
    );
  }

  private getAnimationSlotIndex = (slotIndex: number) => {
    // The numbers in the animation name and data are reversed
    return animationSlotIndexes[slotIndex];
  };

  public changePosition(slotIndex: number) {
    this.multiplierText.position.set(0, slotIndexPer[slotIndex]!.y);
    this.multiplierText.style = {
      ...textStyle,
      fontSize: slotIndexPer[slotIndex]!.fontSize,
    };
  }
}
