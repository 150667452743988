import { Variables } from '../../config';

const GRADIENT_GOLD_AMOUNT_COLOR_CONFIG = {
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#ff4f4f', '#d64fff', '#4fd0ff', '#78ff4f'],
  fillGradientType: 0,
  stroke: '#7bff8444',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 3,
  dropShadowDistance: 3,
  letterSpacing: 2,
  miterLimit: 4,
};
const GRADIENT_GOLD_TITLE_COLOR_CONFIG = {
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#ff4f4f', '#d64fff', '#4fd0ff', '#78ff4f'],
  fillGradientType: 0,
  stroke: '#7bff8444',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 3,
  dropShadowDistance: 3,
  letterSpacing: 2,
  miterLimit: 4,
};
export const titleTextStyle = {
  fontSize: 100,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  strokeThickness: 8,
};
export const descriptionsTextStyle = {
  fontSize: 50,
  fontFamily: Variables.FONT_FAMILY,
  fill: '#FFFFFF',
  stroke: '#000000',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAngle: 1,
  dropShadowDistance: 4,
  letterSpacing: 2,
  miterLimit: 4,
};
export const btnTextStyle = {
  fontSize: 60,
  fontFamily: Variables.FONT_FAMILY,
  fill: '#4fd0ff',
  stroke: '#ffffff66',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAngle: 1.0,
  dropShadowDistance: 4,
  letterSpacing: 4,
  miterLimit: 4,
};
export const totalWinTitleStyles = {
  ...titleTextStyle,
  fontSize: 120,
};
export const totalWinAmountTextStyles = {
  fontSize: 160,
  ...GRADIENT_GOLD_AMOUNT_COLOR_CONFIG,
  strokeThickness: 8,
};
export const FreeSpinsStyles = {
  fontSize: 80,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  strokeThickness: 2,
};
export const andTextStyle = {
  fontSize: 50,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  strokeThickness: 2,
};
export const bonusStreakTextStyle = {
  fontSize: 110,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#b3ffff', '#0078ff', '#007dd4', '#20edff', '#2d28ff', '#0600ff', '#5b53ff', '#00065b', '#ffffff'],
  fillGradientStops: [0.2, 0.38, 0.5, 0.53, 0.55, 0.59, 0.66, 0.8, 1.0],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 4,
  letterSpacing: 2,
  fontWeight: 'bolder',
  lineJoin: 'round',
};
