import { IAddOptions, LoaderResource } from 'pixi.js';

import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';
import { mobileVersionSpineFile, preloadSpineTextures, spineFile } from './spine.generated';

export const MAPPED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.SC]: ResourceTypes.symbolSc,
  [SlotId.A]: ResourceTypes.symbolA,
  [SlotId.B]: ResourceTypes.symbolB,
  [SlotId.C]: ResourceTypes.symbolC,
  [SlotId.D]: ResourceTypes.symbolD,
  [SlotId.E]: ResourceTypes.symbolE,
  [SlotId.F]: ResourceTypes.symbolF,
  [SlotId.G]: ResourceTypes.symbolG,
  [SlotId.H]: ResourceTypes.symbolH,
  [SlotId.I]: ResourceTypes.symbolI,
  [SlotId.J]: ResourceTypes.symbolJ,
};

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}

export const excerptWinBackSymbols = [SlotId.SC];

export const LOADER_TEXTURES: IAddOptions[] = Object.values(ResourceTypes).map((resource) => {
  return { name: resource, url: Resources.getSource(resource) };
});

export const generateTexturePath = (before: string, after: string, isMobile = false): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};

export const SPINE_LOADER_TEXTURES = (isMobile: boolean): IAddOptions[] => {
  return [
    ...spineFile.map((name) => ({
      name,
      url: generateTexturePath(
        '/animations',
        `${name}/${name}.json`,
        isMobile && mobileVersionSpineFile.includes(name),
      ),
    })),
  ];
};

export const PRELOAD_SPINE_TEXTURES: IAddOptions[] = preloadSpineTextures.map((v) => {
  return { name: `spine_${v}`, url: `/animations/desktop/${v}` };
});

export const LOADER_SPRITE_TEXTURES: IAddOptions[] = [
  {
    name: 'coinsAnimation',
    url: '/animations/desktop/coins/coins.json',
  },
  {
    name: 'ui',
    url: '/images/ui/ui.json',
  },
  {
    name: 'phoenixMovie',
    url: '/movie/phoenix.mp4',
    loadType: LoaderResource.LOAD_TYPE.XHR,
  },
];
