import { Container } from 'pixi.js';

import { EventTypes } from '../../global.d';
import { ViewContainer } from '../components/viewContainer';
import { eventManager } from '../config';
import { Ambient } from '../gameView/ambient';

export type BackGroundSkin = 'base' | 'freeSpin';

class Background extends ViewContainer {
  private ambient: Container = new Ambient();

  constructor() {
    super();

    this.addChild(this.ambient);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  override resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;
  }
}
export default Background;
