import { Container } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setCascades, setGameMode, setWinDates } from '../../gql';
import { getRandomNumber, getSlotPerAmount, getSymbolMatrixFromSymbols } from '../../utils';
import { WinData, getPrevWinAmounts } from '../../utils/cascade';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import Tween from '../animations/tween';
import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH, REELS_AMOUNT, eventManager } from '../config';

import { MultiplierSymbolReel } from './multiplierSymbol/multiplierSymbolReel';
import { Reel } from './reel';
import { ReelFrame } from './reelFrame';
import { Spider } from './spider';

export class ReelsContainer extends Container {
  private reelFrame: ReelFrame;

  private spider: Spider;

  public reels: Reel[] = [];

  public multiplierSymbolReels: MultiplierSymbolReel[] = [];

  constructor(symbolMatrix: SlotId[][]) {
    super();
    this.position.set(GAME_CONTAINER_WIDTH / 2, GAME_CONTAINER_HEIGHT / 2);

    this.initReels(symbolMatrix);

    this.reelFrame = new ReelFrame(this);

    this.initMultiplierSymbolReels();

    this.spider = new Spider(this);

    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, this.showStopSlots.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpinAnimation.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupReelPosition.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));
  }

  private initReels(symbolMatrix: SlotId[][]) {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = new Reel(i, symbolMatrix[i]!);
      this.reels[i] = reel;
      this.addChild(reel.container);
    }
  }

  private initMultiplierSymbolReels() {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const mpSymbolReel = new MultiplierSymbolReel(i);
      this.multiplierSymbolReels[i] = mpSymbolReel;
      this.addChild(mpSymbolReel.container);
    }
  }

  private setupReelPosition(symbolMatrix: SlotId[][], _scatterStopCount: number[], multiplierMatrix?: number[][]) {
    this.reels.forEach((reel, i) => {
      reel.startSpinStopAnimation(symbolMatrix[i]!);
    });
    // TODO for display testing
    //const multiplierMatrix: number[][] = [];
    //for (let index = 0; index < REELS_AMOUNT; index++) {
    //  multiplierMatrix.push(Array<number>(FREE_SPINS_SLOTS_PER_REEL_AMOUNT).fill(2));
    //}
    if (multiplierMatrix) {
      this.multiplierSymbolReels.forEach((reel, reelId) => {
        reel.startSpinStopAnimation(multiplierMatrix[reelId]!);
      });
    }

    if (
      setCascades().filter(
        (cascade) => cascade.scatterPresentationStatus === 'sc3→4' || cascade.scatterPresentationStatus === 'sc4',
      ).length &&
      getRandomNumber(100) < 50
    ) {
      eventManager.emit(EventTypes.PHOENIX_START);
    }
  }

  private onStartSpinAnimation() {}

  // fix later(do refactor)
  private onStartWinAnimation(winData: WinData, cascadeStep: number) {
    const winPositions = [...new Set(winData.winPositions.flatMap((positions) => positions))];
    const scatterPositions = winData.scatterPositions;

    const animationChain = new AnimationChain();
    const winAnimationGroups = new AnimationGroup();
    const lostAnimationGroups = new AnimationGroup();

    for (let reelId = 0; reelId < REELS_AMOUNT; reelId++) {
      const reel = this.reels[reelId]!;
      const multiplierSymbolReel = this.multiplierSymbolReels[reelId]!;
      const reelWinPositions: number[] = [];
      for (let slotIndex = 0; slotIndex < getSlotPerAmount(); slotIndex++) {
        const position = slotIndex * REELS_AMOUNT + reelId;
        if (winPositions.includes(position)) {
          reelWinPositions.push(position);
        }
      }

      if (reelWinPositions.length > 0) {
        const positions = reelWinPositions.map((pos) => Math.floor(pos / REELS_AMOUNT));
        // win
        {
          const symbolsWin = reel.createWinAnimation(positions);
          winAnimationGroups.addAnimation(symbolsWin);
          const mpSymbolsWin = multiplierSymbolReel.createWinAnimation(positions);
          if (mpSymbolsWin.animations.length) {
            winAnimationGroups.addAnimation(mpSymbolsWin);
          }
          winAnimationGroups.addOnStart(() => {
            AudioApi.play({ type: ISongs.SONG_032_13_Symbol_Lockon });
          });
        }
        // lost
        {
          const symbolsLost = reel.createLostAnimation(positions);
          lostAnimationGroups.addAnimation(symbolsLost);
          const mpSymbolsLost = multiplierSymbolReel.createLostAnimation(positions);
          lostAnimationGroups.addAnimation(mpSymbolsLost);

          lostAnimationGroups.addOnStart(() => {
            AudioApi.stop({ type: ISongs.SONG_032_13_Symbol_Lockon });
            AudioApi.play({ type: ISongs.SONG_032_14_Symbol_Win });
          });
        }
      }

      if (winData.scatterPresentationStatus !== '' && scatterPositions.length) {
        const reelScatterPositions: number[] = [];
        for (let slotIndex = 0; slotIndex < getSlotPerAmount(); slotIndex++) {
          const position = slotIndex * REELS_AMOUNT + reelId;
          if (scatterPositions.includes(position)) {
            reelScatterPositions.push(position);
          }
        }
        if (reelScatterPositions.length) {
          const scatterWin = reel.createWinAnimation(reelScatterPositions.map((pos) => Math.floor(pos / REELS_AMOUNT)));
          winAnimationGroups.addAnimation(scatterWin);
        }
      }
    }

    if (winAnimationGroups.animations.length) {
      winAnimationGroups.addAnimation(Tween.createDelayAnimation(1200));
    }
    if (lostAnimationGroups.animations.length) {
      lostAnimationGroups.addAnimation(Tween.createDelayAnimation(1000));
    }

    winAnimationGroups.addOnStart(() => {
      this.spider.clearIdleAnimation();

      // TODO fix later(Where to change it?)
      if (winData.scatterPresentationStatus === 'sc3' || winData.scatterPresentationStatus === 'sc4') {
        this.spider.startChangeSkinWithScatterWin();
      }
      this.spider.getSpineAnimation(setGameMode(), 'win').start();

      this.reelFrame.startWinAnimation(setGameMode());
      const delay = Tween.createDelayAnimation(1200);
      delay.addOnComplete(() => {
        AudioApi.play({ type: ISongs.SONG_032_11_Spider_pulse });
        eventManager.emit(EventTypes.START_SPIDER_WIN_ANIMATION);
      });
      delay.start();

      if (cascadeStep === 0) {
        const winAmounts = winData.amounts.reduce((sum, v) => sum + v, 0);
        eventManager.emit(EventTypes.START_WIN_COUNT_UP_MESSAGE, 0, winAmounts);
      } else {
        const prevWinAmounts = getPrevWinAmounts(setWinDates(), cascadeStep); //prevCascadeWinAmounts(cascades, cascadeStep);
        const winAmounts = winData.amounts.reduce((sum, v) => sum + v); //cascades[cascadeStep]!.amounts.reduce((sum, v) => sum + v);
        eventManager.emit(EventTypes.START_WIN_COUNT_UP_MESSAGE, prevWinAmounts, prevWinAmounts + winAmounts);
      }
    });

    if (winData.scatterPresentationStatus !== '' && scatterPositions.length) {
      const playFeatureTrigger = Tween.createDelayAnimation(1000);
      playFeatureTrigger.addOnStart(() => {
        AudioApi.play({ type: ISongs.SONG_FeatureTrigger });
      });
      animationChain.appendAnimation(playFeatureTrigger);
    }
    animationChain.appendAnimation(this.spider.createAttackAnimation());
    animationChain.appendAnimation(winAnimationGroups);
    animationChain.appendAnimation(lostAnimationGroups);

    animationChain.addOnComplete(() => {
      const nextStep = cascadeStep + 1;
      const winDates = setWinDates();
      const cascades = setCascades();
      if (winDates.length > cascades.length && nextStep >= winDates.length) {
        eventManager.emit(EventTypes.AFTER_WIN);
      } else {
        eventManager.emit(EventTypes.NEXT_CASCADE, nextStep);
      }
    });

    animationChain.start();
  }

  private showStopSlots(spinResult: SlotId[]) {
    const symbolMatrix = getSymbolMatrixFromSymbols<SlotId>(spinResult);
    this.reels.forEach((reel, reelId) => {
      for (let i = 0; i < getSlotPerAmount(); i++) {
        const symbols = symbolMatrix[reelId]!;
        reel.initSlots(symbols);
        reel.startIdleAnimation();
      }
    });
  }

  private onChangeMode(_settings: { mode: GameMode }) {
    this.multiplierSymbolReels.forEach((v) => v.clear());
  }
}
