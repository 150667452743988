export enum ResourceTypes {
  basegameBg = 'basegameBg',
  displacementMapRepeat = 'displacementMapRepeat',
  messagebannerA = 'messagebannerA',
  messagebannerB = 'messagebannerB',
  colorMap = 'colorMap',
  lightMap = 'lightMap',
  logo = 'logo',
  particle = 'particle',
  sparks = 'sparks',
  lineLarge = 'lineLarge',
  lineMedium = 'lineMedium',
  lineShort = 'lineShort',
  symbolA = 'symbolA',
  symbolB = 'symbolB',
  symbolC = 'symbolC',
  symbolD = 'symbolD',
  symbolE = 'symbolE',
  symbolF = 'symbolF',
  symbolG = 'symbolG',
  symbolH = 'symbolH',
  symbolI = 'symbolI',
  symbolJ = 'symbolJ',
  symbolSc = 'symbolSc',
  titlelogo = 'titlelogo',
  backdrop = 'backdrop',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  buyFeatureInput = 'buyFeatureInput',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buyFeaturePopup = 'buyFeaturePopup',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  intro01 = 'intro01',
  intro02 = 'intro02',
  intro03 = 'intro03',
  introBg = 'introBg',
  textLogo = 'textLogo',
  waitingLogo = 'waitingLogo',
}
