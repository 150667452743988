import { Variables } from '../../../config';

export const textStyle = {
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#ff5500bb', '#ff5500dd', '#ff5500ff', '#ff5500dd', '#ff5500bb'],
  fillGradientType: 0,
  stroke: '#000000',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 3,
  dropShadowDistance: 3,
  letterSpacing: 2,
  miterLimit: 4,
};
