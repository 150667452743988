import { Variables } from '../../config';

const GRADIENT_GOLD_TEXT_COLOR_CONFIG = {
  fill: ['#47fd53aa', '#47fd53cc', '#47fd53', '#47fd53cc', '#47fd53aa'],
  stroke: '#11111166',
  fillGradientType: 0,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  letterSpacing: 2,
  miterLimit: 1,
};
const GRADIENT_GOLD_TITLE_COLOR_CONFIG = {
  fill: ['#ff4f4f', '#d64fff', '#4fd0ff', '#78ff4f'],
  fillGradientType: 0,
  stroke: '#ffffff44',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  letterSpacing: 2,
  miterLimit: 1,
};
export const buyFeatureTitleStyle = {
  fontSize: 80,
  fontFamily: Variables.FONT_FAMILY,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  strokeThickness: 8,
};
export const buyFeatureConfirmStyle = {
  fontSize: 50,
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#ff4400aa', '#ff4400cc', '#ff4400ff', '#ff4400cc', '#ff4400aa'],
  stroke: '#111111',
  fillGradientType: 0,
  strokeThickness: 5,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 3,
  dropShadowDistance: 1,
  letterSpacing: 2,
  miterLimit: 1,
};
export const totalCostTextStyle = {
  fontSize: 50,
  fontFamily: Variables.FONT_FAMILY,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 5,
};
export const totalCostTextAmountStyle = {
  fontSize: 100,
  fontFamily: Variables.FONT_FAMILY,
  lineHeight: 65,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 10,
};
export const amountTextStyle = {
  fontSize: 50,
  fontFamily: Variables.FONT_FAMILY,
  lineHeight: 50,
  fill: ['#ffffffaa', '#ffffffcc', '#ffffffee', '#ffffffcc', '#ffffffaa'],
  stroke: '#111111',
  strokeThickness: 5,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  letterSpacing: 2,
  wordWrap: false,
  wordWrapWidth: 400,
  breakWords: true,
  miterLimit: 1,
};
export const betValueStyle = {
  fontSize: 80,
  fontFamily: Variables.FONT_FAMILY,
  lineHeight: 50,
  fill: ['#ffffffaa', '#ffffffcc', '#ffffffee', '#ffffffcc', '#ffffffaa'],
  stroke: '#111111',
  strokeThickness: 8,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  letterSpacing: 2,
  miterLimit: 1,
};
export const betValueStyleConfirm = {
  fontSize: 90,
  fontFamily: Variables.FONT_FAMILY,
  lineHeight: 50,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 9,
};
