import { makeVar } from '@apollo/client';

import AudioApi from '@phoenix7dev/audio-api';
import { getUserConfig } from '@phoenix7dev/utils-fe';

import { SlotId } from '../config';
import {
  Cascade,
  GameMode,
  IBonus,
  ISettledBet,
  IUserBalance,
  PopupOpeningTypes,
  UserBonus,
  reelSets,
} from '../global.d';
import { WinData } from '../utils/cascade';

import { IBet, IConfig, ISlotConfig, IStressful } from './d';

export const setIsAuthorized = makeVar<boolean>(false);

export const setProgress = makeVar<{ status: number; wasLoaded?: boolean }>({
  status: 0,
  wasLoaded: false,
});

export const setIsSoundOn = makeVar<boolean>(getUserConfig<IConfig>('config', 'isSoundOn', true));

export const setIsShowSoundToast = makeVar<boolean>(AudioApi.isRestricted);

export const setIsRevokeThrowingError = makeVar<boolean>(false);

export const setIsSuspended = makeVar<boolean>(false);

export const setBrokenGame = makeVar<boolean>(false);

export const setBrokenBuyFeature = makeVar<boolean>(false);

export const setPrevReelsPosition = makeVar<number[]>([0, 0, 0, 0, 0]);

export const setUserLastBetResult = makeVar<IBet>({
  id: '',
  coinAmount: 1,
  coinValue: 1,
  result: {
    reelPositions: [],
    winCoinAmount: 0,
  },
  data: {
    bonuses: [],
    features: {
      gameRoundStore: {
        cascadeData: {
          cascadeHistoryRound: [],
          multiplierHistory: [],
          paylines: [],
          spinMatrixesChanges: [],
        },
      },
    },
  },
  reelSetId: '',
  createdAt: '',
  updatedAt: '',
  userBonus: { betId: '', bonusId: '', bonus: { coinAmount: 0 } },
});

export const setIsFreeSpinsWin = makeVar<boolean>(false);

export const setSoundValue = makeVar<number>(Number(setIsSoundOn()));

export const setIsSpinInProgress = makeVar<boolean>(false);

export const setIsMobile = makeVar<boolean>(false);

export const setIsSlotBusy = makeVar<boolean>(false);

export const setIsProcessToGame = makeVar<boolean>(false);

export const setBetAmount = makeVar<number>(1);

export const setCurrentFreeSpinsTotalWin = makeVar<number>(0);

export const setCoinValue = makeVar<number>(1);

export const setCoinAmount = makeVar<number>(1);

export const setWinAmount = makeVar<number>(0);

export const setLastRegularWinAmount = makeVar<number>(0);

export const setAutoSpinsAmount = makeVar<number>(0);

export const setStopOnWinExceeds = makeVar<number>(0);

export const setStopOnWinExceedsSliderValue = makeVar<number>(1);

export const setStopOnBalanceIncreaseSliderValue = makeVar<number>(1);

export const setStopOnBalanceIncrease = makeVar<number>(0);

export const setStopOnBalanceDecrease = makeVar<number>(0);

export const setIsContinueAutoSpinsAfterFeature = makeVar<boolean>(false);

export const setIsStopOnAnyWin = makeVar<boolean>(false);

export const setIsStopOnFeatureWin = makeVar<boolean>(true);

export const setIsStopOnWinExceeds = makeVar<boolean>(false);

export const setIsStopOnBalanceIncrease = makeVar<boolean>(false);

export const setIsStopOnBalanceDecrease = makeVar<boolean>(false);

export const setIsAutoSpins = makeVar<boolean>(false);

export const setAutoSpinsLeft = makeVar<number>(0);

// todo replace with real backend logic
export const setFreeSpinsTotalWin = makeVar<number>(0);

export const setGameMode = makeVar<GameMode>(GameMode.BASE_GAME);

export const setCurrency = makeVar<string>('FUN');

export const setUserBalance = makeVar<IUserBalance>({
  balance: { amount: 0, currency: '' },
  clientId: '',
  id: '',
});

export const setReelSetId = makeVar<string>('');

export const setBetResult = makeVar<ISettledBet | null>(null);

export const setCurrentBonusId = makeVar<string>('');

export const setCurrentBonus = makeVar<UserBonus>({
  id: '',
  bonusId: '',
  betId: '',
  status: '',
  coinValue: 1,
  coinAmount: 1,
  rounds: 10,
  data: {
    count: 0,
    maxRounds: 0,
    storeCoinValue: false,
    debitMultiplier: 0,
    storeCoinAmount: false,
    creditMultiplier: 0,
  },
  totalWinAmount: 0,
  bonus: {
    id: '',
    reelSetId: '',
    type: '',
    coinAmount: 1,
    purchasable: false,
    rounds: 0,
    data: {},
  },
  isActive: false,
  // undefined
  reelSetId: reelSets[GameMode.BASE_GAME]!,
  currentRound: 0,
  roundsPlayed: 0,
});

export const setAutoSpinsStartBalance = makeVar<number>(0);

export const setSlotConfig = makeVar<ISlotConfig>({
  id: '',
  lineSet: {
    id: '',
    slotId: '',
    lines: [],
    coinAmountMultiplier: 0,
  },
  clientSettings: {
    coinAmounts: {
      default: [],
      quick: [],
    },
    coinValues: [],
    features: [],
    autoplay: {
      options: [],
      conditions: {
        stopOnAnyWin: {
          enabled: false,
        },
        stopIfFeatureIsWon: {
          enabled: false,
        },
        stopIfSingleWinExceeds: {
          enabled: false,
          multipliers: [],
        },
        stopIfBalanceDecreasesBy: {
          enabled: false,
          multipliers: [],
        },
        stopIfBalanceIncreasesBy: {
          enabled: false,
          multipliers: [],
        },
      },
    },
  },
  lines: [],
  sessionId: '',
  reels: [],
  icons: [],
  winLines: [],
  settings: {
    startPosition: [],
  },
  isBuyFeatureEnabled: true,
});

export const setBonuses = makeVar<IBonus[]>([]);

export const setGameHistory = makeVar<boolean[]>([false, false, false]);

export const setIsTurboSpin = makeVar<boolean>(getUserConfig<IConfig>('config', 'isTurboSpin', false));

export const setStressful = makeVar<IStressful>({
  show: false,
  type: 'none',
  message: '',
});

export const setIsBuyFeaturePopupOpened = makeVar<boolean>(false);

export const setIsDuringBigWinLoop = makeVar<boolean>(false);

export const setIsSoundLoading = makeVar<boolean>(false);

export const setIsOpenedMessageBanner = makeVar<boolean>(false);

export const setReel = makeVar<SlotId[][]>([]);

export const setIsPopupOpened = makeVar<boolean>(false);

export const setIsOpenInfoPopup = makeVar<boolean>(false);

export const setIsOpenHistoryPopup = makeVar<boolean>(false);

export const setIsOpenBetSettingsPopup = makeVar<boolean>(false);

export const setIsOpenAutoplayPopup = makeVar<boolean>(false);

export const setIsPopupOpeningInProgress = makeVar<PopupOpeningTypes>(PopupOpeningTypes.NONE);

export const setIsDuringWinCountUpAnimation = makeVar<boolean>(false);

export const setIsTransition = makeVar<boolean>(false);

export const setScatterPositions = makeVar<number[]>([]);

export const setIsBuyFeaturePurchased = makeVar<boolean>(false);

export const setIsTimeoutErrorMessage = makeVar(false);

export const setReplayBet = makeVar<string>('');

export const setHistoryReplayBet = makeVar<ISettledBet | null>(null);

export const setLastRegularHistory = makeVar<{
  gameMode: GameMode;
  betAmount: number;
  coinAmount: number;
  balance: { amount: number; currency: string };
  winAmount: number;
  reelSetId: string;
  reelPositions: number[];
  slotIds: SlotId[];
}>({
  gameMode: GameMode.BASE_GAME,
  betAmount: 0,
  coinAmount: 0,
  balance: { amount: 0, currency: '' },
  winAmount: 0,
  reelSetId: '',
  reelPositions: [],
  slotIds: [],
});

export const setIsHistoryVisible = makeVar<boolean>(true);

export const setWinDates = makeVar<WinData[]>([]);

export const setCascades = makeVar<Cascade[]>([]);
