import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/viewContainer';
import { eventManager } from '../config';

class FadeArea extends ViewContainer {
  private sprite: PIXI.Sprite;

  constructor() {
    super();
    this.sprite = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.sprite.width = 100;
    this.sprite.height = 100;
    this.sprite.tint = 0x000000;
    this.alpha = 0;
    this.addChild(this.sprite);
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.START_FADE_AREA_ANIMATION, this.startFadeAnimation.bind(this));
  }

  private startFadeAnimation(
    duration: number,
    callbackOutComplete?: () => void,
    callbackInComplete?: () => void,
  ): void {
    const animationChain = new AnimationChain();
    const fadeOut = this.getFadeAnimation(1, duration / 2);
    fadeOut.addOnComplete(() => {
      callbackOutComplete?.();
    });
    const fadeIn = this.getFadeAnimation(0, duration / 2);
    fadeIn.addOnComplete(() => {
      callbackInComplete?.();
    });
    animationChain.appendAnimation(fadeOut);
    animationChain.appendAnimation(fadeIn);
    animationChain.start();
  }

  private getFadeAnimation(alpha: number, duration: number): Animation {
    const animation = new Tween({
      object: this,
      duration,
      propertyBeginValue: alpha === 1 ? 0 : 1,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }

  override resize(width: number, height: number): void {
    this.sprite.width = width;
    this.sprite.height = height;
  }
}

export default FadeArea;
