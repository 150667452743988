import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { MAPPED_SYMBOLS } from '../../config';
import { IUserBalance } from '../../global';
import { setCoinValue, setSlotConfig } from '../../gql/cache';
import { getBetAmountGql, getUserGql } from '../../gql/query';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { Combos, Icon, IconCombo } from '../../slotMachine/d';
import { countCoins, showCurrency } from '../../utils';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const calcMultiplier = (multiplier: number, betAmount: number, slug: ResourceTypes): number => {
  if (slug === ResourceTypes.symbolSc) {
    return countCoins({ totalAmount: betAmount, coinValue: setCoinValue() }) * multiplier;
  }

  return (
    countCoins({
      totalAmount: betAmount / setSlotConfig().lineSet.coinAmountMultiplier,
      coinValue: setCoinValue(),
    }) * multiplier
  );
};

/* const hasTranslate = (_slug: ResourceTypes): boolean => {
  //return slug === ResourceTypes.symbolWl || slug === ResourceTypes.symbolSc;
  return false;
}; */

const getCombosDataFromIcon = (icon: Icon): Combos => {
  return icon
    .combos!.flatMap((combo) => {
      const coinReward = combo.rewards.find((v) => v.type === 'COINS');
      if (coinReward) {
        return {
          pattern: combo.pattern,
          multiplier: coinReward.multiplier!,
        };
      }
      return [];
    })
    .sort((a, b) => {
      const patternA = a.multiplier; //a.pattern;
      const patternB = b.multiplier; //b.pattern;
      if (patternA < patternB) {
        return 1;
      }
      if (patternA > patternB) {
        return -1;
      }
      return 0;
    })
    .sort((a, b) => {
      const patternA = a.multiplier; //a.pattern;
      const patternB = b.multiplier; //b.pattern;
      if (patternA < patternB) {
        return 1;
      }
      if (patternA > patternB) {
        return -1;
      }
      return 0;
    });
};

const getSortedIcons = () => {
  return [...setSlotConfig().icons].sort((a, b) => {
    const idA = a.id.toString().length;
    const idB = b.id.toString().length;
    if (idA < idB) {
      return 1;
    }
    if (idA > idB) {
      return -1;
    }
    return 0;
  });
};

const getCombos = (iconCombos: Combos) => {
  return iconCombos?.reduce((acc: IconCombo[], iconComb: IconCombo) => {
    if (acc.length === 0 || acc.filter((v) => v.multiplier === iconComb.multiplier).length === 0) {
      switch (iconComb.pattern) {
        case 'x8':
          iconComb.pattern += '-10';
          break;
        case 'x11':
          iconComb.pattern += '-13';
          break;
        case 'x14':
          iconComb.pattern += '-16';
          break;
        case 'x17':
          iconComb.pattern += '+';
          break;
        default:
          break;
      }
      acc.push(iconComb);
    }
    return acc;
  }, []);
};

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);

  const {
    user: { balance },
  } = userData!;

  const { betAmount } = dataBet!;
  const { currency } = balance;

  const icons = getSortedIcons();

  const data = icons.map((icon) => {
    const iconCombos = getCombosDataFromIcon(icon);
    const combos = getCombos(iconCombos);
    return { combos, slug: MAPPED_SYMBOLS[icon.id] };
  });

  return (
    <section>
      <h1 className={styles.title}>{t('infoTabPayTable')}</h1>
      <div className={styles['paytable-list']}>
        {data.map(({ combos, slug }) => (
          <React.Fragment key={slug}>
            {slug === ResourceTypes.symbolSc && (
              <div key={slug} className={styles['paytable-list__scatter']}>
                <div className={styles.img}>
                  <div className={styles.image__title}>
                    {i18n.exists(`infoPayTable.${slug}`) && t(`infoPayTable.${slug}`, '')}
                  </div>
                  <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                </div>
                <table className={styles.content}>
                  <tbody>
                    {combos!.map((combo) => (
                      <tr key={combo.pattern}>
                        <td>
                          <span className={styles.multiplier}>{combo.pattern} </span>
                          {formatNumber({
                            currency,
                            value: calcMultiplier(combo.multiplier, betAmount, slug),
                            showCurrency: showCurrency(currency),
                          })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className={styles['paytable-list']}>
        {data.map(({ combos, slug }) => (
          <React.Fragment key={slug}>
            {slug !== ResourceTypes.symbolSc && (
              <div key={slug} className={styles['paytable-list__item']}>
                <div className={styles.img}>
                  <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                </div>
                <table className={styles.content}>
                  <tbody>
                    {combos!.map((combo) => (
                      <tr key={combo.pattern}>
                        <td>
                          <span className={styles.multiplier}>{combo.pattern} </span>
                          {formatNumber({
                            currency,
                            value: calcMultiplier(combo.multiplier, betAmount, slug),
                            showCurrency: showCurrency(currency),
                          })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </section>
  );
};

export default PaytableComponent;
