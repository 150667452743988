import { Container } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { Game } from '../../game';
import { EventTypes, GameMode } from '../../global.d';
import { setGameMode } from '../../gql';
import { getRandomNumber, isFreeSpinMode, isRegularMode } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { StrictSpine } from '../components/spine';
import { REELS_AMOUNT, SlotMachineState, eventManager } from '../config';

type SpiderAnimationNames = SpineInterface['spider']['animations'];

enum SpiderIdleState {
  NONE,
  WAITING,
  MOVING,
}

export class Spider {
  private spine: StrictSpine<'spider'>;

  private idleAnimation: Animation | null = null;

  private idleState: SpiderIdleState = SpiderIdleState.NONE;

  private modeName: 'bg' | 'fg' = 'bg';

  private rotationTargetAngle = 0;

  constructor(container: Container) {
    this.spine = Game.getInstance().maker.spine('spider');
    this.spine.stateData.setMix('bg_move_l', 'bg_idle', 0.5);
    this.spine.stateData.setMix('bg_move_r', 'bg_idle', 0.5);
    this.spine.stateData.setMix('fg_move_l', 'fg_idle', 0.5);
    this.spine.stateData.setMix('fg_move_r', 'fg_idle', 0.5);
    this.setSkin('base_core');
    this.spine.scale.set(0.5, 0.5); // TODO will fix
    container.addChild(this.spine);

    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, (state: SlotMachineState) => {
      if (state === SlotMachineState.IDLE) {
        this.startIdleAnimation();
      }
    });
    eventManager.addListener(EventTypes.CHANGE_MODE, (settings: { mode: GameMode }) => {
      this.onChangeMode(settings.mode);
    });
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, (settings: { mode: GameMode }) =>
      this.onChangeMode(settings.mode),
    );

    this.onChangeMode(GameMode.BASE_GAME);
  }

  private setIdleAnimation(mode: GameMode) {
    this.modeName = isFreeSpinMode(mode) ? 'fg' : 'bg';
    const animationName = `${this.modeName}_idle` as SpiderAnimationNames;
    this.spine.state.setEmptyAnimation(0, 0);
    this.spine.state.setAnimation(0, animationName, true);
  }

  private startIdleAnimation() {
    if (this.idleState === SpiderIdleState.NONE) {
      this.idleAnimation = this.createIdleAnimation(500, 2000);
      this.idleAnimation.start();
    }
  }

  public clearIdleAnimation() {
    if (this.idleAnimation) {
      this.idleAnimation = null;
      this.idleState = SpiderIdleState.NONE;
    }
  }

  public startChangeSkinWithScatterWin(animationName: SpiderAnimationNames = 'bg_win') {
    const duration = this.spine.getAnimation(0, animationName).duration / 2;
    Tween.setTimeout(() => {
      this.setSkin('feauture_core');
    }, duration).start();
  }

  public createAttackAnimation() {
    const animationChain = new AnimationChain();

    if (this.idleAnimation && this.idleState === SpiderIdleState.MOVING) {
      const waitTime = this.idleAnimation.duration - this.idleAnimation.startTime;
      const wait = Tween.createDelayAnimation(waitTime);
      wait.addOnComplete(() => this.clearIdleAnimation());
      animationChain.appendAnimation(wait);
    } else {
      animationChain.addOnStart(() => this.clearIdleAnimation());
    }

    const propertyBeginValue = (this.rotationTargetAngle * Math.PI) / 180;
    const moveLeftOrRight = 1;
    const nextAngle = 360;
    const target = ((this.rotationTargetAngle + moveLeftOrRight * nextAngle) * Math.PI) / 180;
    const duration = 500;

    const rotationAnimation = new Tween({
      object: this.spine,
      property: TweenProperties.ROTATION,
      propertyBeginValue,
      target,
      duration,
      update: (value) => {
        const angle = (value * 180) / Math.PI;
        this.spine.angle = angle;
      },
    });
    const spiderAttack = this.getSpineAnimation(setGameMode(), 'attack');
    spiderAttack.addOnStart(() => {
      AudioApi.play({ type: ISongs.SONG_032_10_Spider_roll });
    });
    const animationGroup = new AnimationGroup();
    animationGroup.addAnimation(rotationAnimation);
    animationGroup.addAnimation(spiderAttack);
    animationChain.appendAnimation(animationGroup);

    return animationChain;
  }

  public getSpineAnimation(
    mode: GameMode,
    animationAbbv: 'appeal' | 'attack' | 'idle' | 'move_l' | 'move_r' | 'win',
  ): Animation {
    const modeAbbv = isRegularMode(mode) ? 'bg' : 'fg';
    const animationName = `${modeAbbv}_${animationAbbv}` as SpiderAnimationNames;
    return this.spine.getAnimation(0, animationName);
  }

  public setSkin(setSkinName: SpineInterface['spider']['skins'] = 'base_core') {
    const skinName = setSkinName as SpineInterface['spider']['skins'];
    this.spine.skeleton.setSkinByName(skinName);
    this.spine.skeleton.setToSetupPose();
  }

  private createIdleAnimation(minMsec: number, maxMsec: number) {
    const animationName = `${this.modeName}_idle` as SpiderAnimationNames;
    const animation = Tween.createDelayAnimation(Math.floor(Math.random() * (maxMsec - minMsec)) + minMsec);

    animation.addOnStart(() => {
      const trackEntry = this.spine.state.getCurrent(0);
      // only when not idling
      if (!trackEntry || trackEntry.animation?.name !== animationName) {
        this.spine.state.setAnimation(0, animationName, true);
      }
      this.idleState = SpiderIdleState.WAITING;
    });
    animation.addOnComplete(() => {
      this.startNextIdleAnimation();
    });

    return animation;
  }

  private createMoveAnimation(minReelNumber: number, maxReelNumber: number) {
    const propertyBeginValue = (this.spine.angle * Math.PI) / 180;
    const moveLeftOrRight = Math.random() > 0.5 ? -1 : +1;
    const nextAngle = (360 / REELS_AMOUNT) * getRandomNumber(maxReelNumber, minReelNumber);
    const targetAngle = this.spine.angle + moveLeftOrRight * nextAngle;
    const target = ((this.spine.angle + moveLeftOrRight * nextAngle) * Math.PI) / 180;
    const duration = nextAngle * 100 > 1000 ? 1000 : nextAngle * 100;

    const moveAnimation = new Tween({
      object: this.spine,
      property: TweenProperties.ROTATION,
      propertyBeginValue,
      target,
      duration,
      update: (value) => {
        const angle = (value * 180) / Math.PI;
        this.spine.angle = angle;
      },
    });

    moveAnimation.addOnStart(() => {
      const animationName = `${this.modeName}_${moveLeftOrRight === 1 ? 'move_l' : 'move_r'}` as SpiderAnimationNames;
      this.spine.state.setAnimation(0, animationName, true);
      this.idleState = SpiderIdleState.MOVING;
      this.rotationTargetAngle = targetAngle;
    });
    moveAnimation.addOnComplete(() => {
      this.rotationTargetAngle = this.spine.angle;
      this.startNextIdleAnimation();
    });

    return moveAnimation;
  }

  private startNextIdleAnimation() {
    if (this.idleState === SpiderIdleState.NONE) return;

    if (this.idleState !== SpiderIdleState.WAITING) {
      this.idleAnimation = this.createIdleAnimation(1000, 1500);
    } else {
      const rand = Math.random();
      if (rand > 0.5) {
        this.idleAnimation = this.createMoveAnimation(1, 4);
      } else {
        this.idleAnimation = this.createIdleAnimation(500, 1000);
      }
    }

    this.idleAnimation.start();
  }

  private onChangeMode(mode: GameMode) {
    this.setSkin(isFreeSpinMode(mode) ? 'feauture_core' : 'base_core');
    this.setIdleAnimation(mode);
    this.startIdleAnimation();
  }
}
